@import '../../../../scss/theme-bootstrap';

.content-block {
  &__disclaimer {
    &--title {
      @include typestyle--b1;
      box-shadow: 0 2px 0 -1px $color-darker-gray;
      color: $color-darker-gray;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 20px;
      padding-bottom: 1px;
      .content-block--tout & {
        margin-top: 20px;
      }
      .content-block-split-width.asymmetrical-feature__single & {
        margin-top: 10px;
      }
    }
    &--content {
      color: $color-darker-gray;
      display: none;
      font-size: 12px;
      letter-spacing: 0.02rem;
      line-height: 1.5;
      margin-bottom: 20px;
      p {
        color: $color-darker-gray;
        margin-bottom: 0;
      }
    }
  }
}
